<!--用户用电缴费情况-->

<template>
    <widget-framework v-if="dataLoaded" title="用电用户缴费状态" :show-border="true" @click="$router.push('/business/customerAccount')">
        <template #content>
            <el-row style="height: 20%">
                <el-col :span="12" class="item">
                    <span class="name">用电用户数量：</span>
                    <span class="value">{{userAmount}}</span>
                </el-col>
                <el-col :span="12" class="item">
                    <span class="name">应缴总额：</span>
                    <span class="value">{{paid}}元</span>
                </el-col>
            </el-row>
            <el-row style="height: 80%">
                <v-chart :options="opt" :autoresize="true" style="width: 100%; height: 100%"></v-chart>
            </el-row>
        </template>
    </widget-framework>
    <dv-loading v-else></dv-loading>
</template>

<script>
import WidgetFramework from '@/components/widgets/WidgetFramework';
import WidgetBase from '@/components/widgets/WidgetBase';
import EchartBase from '@/components/widgets/EchartBase';
import numeral from 'numeral';
import {yellow, blue, cyan, white, whiteOpacity, lightGrey, filledCircle, tooltip, red, green} from '../constant';

export default {
    name: 'PaymentStatus',
    components: {
        WidgetFramework
    },
    mixins: [WidgetBase, EchartBase],
    data() {
        return {
            dataLoaded: false,
            opt: {
                textStyle: {
                    color: lightGrey
                },
                tooltip: {
                    trigger: 'item',
                    position: this.setTooltipPosition,
                    backgroundColor: tooltip.backgroundColor,
                    borderColor: tooltip.borderColor,
                    borderWidth: tooltip.borderWidth,
                    extraCssText: tooltip.extraCssText
                },
                dataset: [],
                series: [
                    {
                        name: '实缴总额',
                        type: 'pie',
                        radius: [0, '60%'],
                        hoverAnimation: false,
                        itemStyle: {
                            color: filledCircle
                        },
                        label: {
                            show: true,
                            position: 'center',
                            formatter: (params) => {
                                const {value} = params.data;
                                return `{a|${numeral(value).format('0%')}}\n{b|实缴总额}`;
                            },
                            rich: {
                                a: {
                                    color: yellow,
                                    fontSize: 20,
                                    height: 30,
                                    fontWeight: 'bold'
                                },
                                b: {
                                    color: white,
                                    fontSize: 12
                                }
                            }
                        },
                        labelLine: {
                            show: false,
                        },
                        datasetIndex: 0,
                        encode: {
                            value: 'value'
                        },
                        tooltip: {
                            formatter: (params) => {
                                const {value} = params.data;
                                return `实缴总额：${numeral(value).format('0%')}`;
                            }
                        }
                    },
                    {
                        name: '缴费情况',
                        type: 'pie',
                        radius: ['50%', '65%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: true,
                            position: 'outside',
                            formatter: (params) => {
                                const {name, value} = params.data;
                                if (name === '待缴总额') {
                                    return `{c|${value}元}\n{b|${name}}`;
                                }
                                return `{a|${value}元}\n{b|${name}}`;
                            },
                            alignTo: 'labelLine',
                            lineHeight: 14,
                            rich: {
                                a: {
                                    color: cyan,
                                    fontSize: 12,
                                },
                                b: {
                                    color: white,
                                    fontSize: 12,
                                },
                                c: {
                                    color: red,
                                    fontSize: 12,
                                }
                            }
                        },
                        labelLine: {
                            show: true,
                            length2: 40,
                        },
                        emphasis: {
                            label: {
                                show: false,
                            },
                        },
                        datasetIndex: 1,
                        encode: {
                            value: 'value'
                        },
                        tooltip: {
                            formatter: (params) => {
                                const {name, value} = params.data;
                                return `${name}：${value}kW`;
                            }
                        }
                    },
                    {
                        type: 'pie',
                        radius: ['78%', '80%'],
                        legendHoverLink: false,
                        hoverAnimation: false,
                        itemStyle: {
                            color: whiteOpacity
                        },
                        label: {
                            show: false,
                        },
                        labelLine: {
                            show: false,
                        },
                        data: [1],
                        markPoint: {
                            symbol: 'none'
                        },
                        tooltip: {
                            show: false,
                        },
                        z: 0
                    },
                    {
                        type: 'pie',
                        radius: ['85%', '90%'],
                        legendHoverLink: false,
                        hoverAnimation: false,
                        itemStyle: {
                            color: whiteOpacity
                        },
                        label: {
                            show: false,
                        },
                        labelLine: {
                            show: false,
                        },
                        data: [1],
                        markPoint: {
                            symbol: 'none'
                        },
                        tooltip: {
                            show: false,
                        },
                        z: 0
                    }
                ]
            },
            userAmount: null
        };
    },
    mounted() {
        this.$client.getPaymentStatus().then((ret) => {
            const {userAmount, paidAmount, unPaidAmount} = ret.data;
            const dataset = [];
            const dataset1 = [];

            dataset1.push({name: '待缴总额', value: unPaidAmount, itemStyle: {color: yellow}});
            dataset1.push({name: '实缴总额', value: paidAmount, itemStyle: {color: blue}});
            let rate = 1;
            if (paidAmount + unPaidAmount !== 0) {
                rate = paidAmount / (paidAmount + unPaidAmount);
            }
            dataset.push({source: [{name: '实缴总额', value: rate}]});
            dataset.push({source: dataset1});
            this.opt.dataset = dataset;
            this.userAmount = userAmount;
            this.paid = unPaidAmount + paidAmount;
            this.dataLoaded = true;
        });
    }
};
</script>

<style lang="less" scoped>
@import '~@/assets/style/variables.less';
.item {
    font-size: 12px;
    text-align: center;
    margin-top: 15px;

    .name {
        color: @white;
    }
    .value {
        color: @yellow;
    }
}

</style>
